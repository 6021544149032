<template>
  <div class="acc-wrap">
    <div class="acc-content">
      <img src="@/assets/images/myFavors/succ.png">
      <h3>恭喜！开户成功</h3>
      <p>即刻开启财富生活，完善风险测评，享有更佳体验。</p>
      <div class="btn-w">
        <span @click="$router.replace('/home')">回首页</span>
        <span @click="$router.replace('/myFavors/accountManage/riskAssessment')">风险测评</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
@import url('@/assets/css/zc-common.less');
.acc-wrap {
  width: 100%;
  background-color: #F3F4F6;
  padding: 35px 0;
  .acc-content {
    width: 1200px;
    min-height: 544px;
    margin: 0 auto;
    padding-top: 86px;
    padding-bottom: 44.32px;
    background-color: #fff;
    box-shadow: 0px 4px 12px #EDF1F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    &>img {
      width:300.56px;
      height:  222.68px;
    }
    &>h3 {
      margin-top: 50px;
      font-weight: bold;
      font-size: 18px;
      color: #1F1F1F;
    }
    &>p {
      margin-top: 14px;
      font-size: 16px;
      color: #1F1F1F;
    }
    .btn-w {
      width: 100%;
      margin-top: 44px;
      text-align: center;
      span {
        &:first-child {
          .inline-flex(center, center, row);
          width: 240px;
          height: 48px;
          background: #FFFFFF;
          border: 1px solid #E0E2E8;
          border-radius: 2px;
          font-size: 16px;
          color: #1F1F1F;
        }
        &:last-child {
          .btn(240px,48px);
          margin-left: 36px;
        }
      }
    }
  }
}
</style>
